/* Change this file to get your personal Portfolio */

// Summary And Greeting Section

import emoji from "react-easy-emoji";

const illustration = {
  animated: true // set to false to use static SVG
};

const greeting = {
  username: "Hamdi Halimi",
  title: "Hi all",
  subTitle: (
    "A passionate Web App Developer  having an experience of designing and building Web  applications with JavaScript / Reactjs / Gatsby Js /Node js and some other cool libraries and frameworks."
  ),
  resumeLink:
  require("./assets/images/Hamdi-HalimI -cv.pdf"),
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  github: "https://github.com/hamdihalimi",
  linkedin: "https://www.linkedin.com/in/hamdi-halimi-a28801178/",
  gmail: "hamdihalimi93@gmail.com",
  gitlab: "https://gitlab.com/hamdihalimi",
  facebook: "https://www.facebook.com/hamdi.halimi/",
  instagram: 'https://www.instagram.com/hamdihalimi93/',
  display: true // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: "What I do",
  subTitle: "",
  skills: [
    emoji(
      "⚡ Develop highly interactive Front end / User Interfaces for your web  applications"
    ),
    emoji("⚡ Progressive Web Applications ( PWA ) in normal and SPA Stacks"),
    emoji(
      "⚡ Integration of third party services such as Firebase/ AWS / Digital Ocean"
    )
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "html-5",
      fontAwesomeClassname: "fab fa-html5"
    },
    {
      skillName: "css3",
      fontAwesomeClassname: "fab fa-css3-alt"
    },
    {
      skillName: "sass",
      fontAwesomeClassname: "fab fa-sass"
    },
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js"
    },
    {
      skillName: "reactjs",
      fontAwesomeClassname: "fab fa-react"
    },
    {
      skillName: "bootsrap",
      fontAwesomeClassname: "fab fa-bootstrap"
    },
  
    {
      skillName: "npm",
      fontAwesomeClassname: "fab fa-npm"
    },
    {
      skillName: "firebase",
      fontAwesomeClassname: "fas fa-fire"
    },
    {
      skillName: "Node Js",
      fontAwesomeClassname: "fab fa-node-js"
    },
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [

    {
      schoolName: "Faculty of Natural Sciences – UNIVERSITY OF TIRANA",
      logo: require("./assets/images/shkencatnatyres.png"),
      subHeader: "Bachelor of Mathematics and Computing / Engineering",
      duration: "September 2012 - April 2017",
    }
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: true, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "HTML5/ CSS3", //Insert stack or technology you have experience in
      progressPercentage: "90%" //Insert relative proficiency in percentage
    },
    {
      Stack: "JavaScript/ jQuery", //Insert stack or technology you have experience in
      progressPercentage: "70%" //Insert relative proficiency in percentage
    },
    {
      Stack: "React Js ", //Insert stack or technology you have experience in
      progressPercentage: "70%" //Insert relative proficiency in percentage
    },
    {
      Stack: "Node Js ", //Insert stack or technology you have experience in
      progressPercentage: "40%" //Insert relative proficiency in percentage
    },

  ],
  displayCodersrank: true // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "Frontend Developer",
      company: "Smart Point",
      companylogo: require("./assets/images/smartpoint.png"),
      date: "April 2019 – Octember 2019",
      desc:
        "Build reusable code and libraries for future use.",
      descBullets: [
        "Build reusable code and libraries for future use.", 
        "Develop new user facing features.", 
        "Design, develop and relaunch responsive websites and e-commerce sites of clients. Ensure the technical feasibility of UI/UX designs.",
        "Define how the website looks and how it works.", 
        "Translate UI/UX design wireframes to actual code",
        "Worked with JSON objects and JavaScript and JQuery intensively to create interactive web pages.", 
        "Coded frontend interface with Bootstrap,CSS, JS and HTML5.", 
        "Created various UI components for different web pages using HTML, Javascript,", 
        "Built Web pages that are more user-interactive using JavaScript, and Bootstrap.", 
        "Worked in highly collaborative agile team environment.", 
        "Oversees creation and maintenance of front-end standard documents.",
        "Coordinates with copywriters and graphic artists on integration of images, icons, banners, audio and other features of the website."

      ]
    },
    {
      role: "Frontend Developer",
      company: "Karrota",
      companylogo: require("./assets/images/Karrota.jpg"),
      date: "November 2019 – June 2021",
      desc:
        "Design, develop and relaunch responsive websites and e-commerce sites of clients.",
        descBullets: [
         "Build reusable code and libraries for future use.", 
         "Develop new user facing features.",
         "Design, develop and relaunch responsive websites and e-commerce sites of clients. Ensure the technical feasibility of UI/UX designs.",
         "Define how the website looks and how it works",
         "Translate UI/UX design wireframes to actual code", 
         "Worked with JSON objects and JavaScript and JQuery intensively to create interactive web pages.",
         "Coded frontend interface with Bootstrap,CSS, JS and HTML5.",
         "Created various UI components for different web pages using HTML, Javascript", 
         "Built Web pages that are more user-interactive using JavaScript, and Bootstrap.", 
         "Worked in highly collaborative agile team environment.", 
         "Oversees creation and maintenance of front-end standard documents.",
         "Coordinates with copywriters and graphic artists on integration of images, icons, banners, audio and other features of the website."
         
        ]
    },
    {
      role: "Front End Developer",
      company: "Kosbit",
      companylogo: require("./assets/images/kosbit.png"),
      date: "12 July 2021 – 28 February 2022",
      desc:
        "Web  App Developer , with  React Js and  Gatsby  Js.",
        descBullets: [

          "Responsible to Style ,look and feel of the web page with SASS that extends CSS with dynamic behavior such as variable , mixing, operations and functions.",
          "Implemented various screens for the front end using React.js and used various predefined components from NPM (Node Package Manager) and redux library.",
          "Worked on React JS Virtual Dom and React views, rendering using components which contains additional components called custom HTML tags.",
          "Worked in using React JS components, Forms, Events, Keys, Router, Animations, and Flux concept.",
          "Used React JS for templating for faster compilation and developing reusable components.",
          "Responsible for React UI and architecture.Building components ibrary,including Tree,Slide-View,and Table Grid.",
          "Implemented flux pattern by using redux framework as a core dependency.",
          "Utilized React for its efficient data flow architecture to create a lightweight and render efficient web app that searched projects via the GitHub API through keywords.",
        ]
    },
    {
      role: "Full Stack Developer",
      company: "TrioKos",
      companylogo: require("./assets/images/Trio-Kos-Logo.png"),
      date: "12 July 2022 – 31 May 2023",
      desc:
        "Web  App Developer , with  React Js and  Gatsby  Js.",
        descBullets: [

          "Responsible to Style ,look and feel of the web page with SASS that extends CSS with dynamic behavior such as variable , mixing, operations and functions.",
          "Implemented various screens for the front end using React.js and used various predefined components from NPM (Node Package Manager) and redux library.",
          "Worked on React JS Virtual Dom and React views, rendering using components which contains additional components called custom HTML tags.",
          "Worked in using React JS components, Forms, Events, Keys, Router, Animations, and Flux concept.",
          "Used React JS for templating for faster compilation and developing reusable components.",
          "Responsible for React UI and architecture.Building components ibrary,including Tree,Slide-View,and Table Grid.",
          "Implemented flux pattern by using redux framework as a core dependency.",
          "Utilized React for its efficient data flow architecture to create a lightweight and render efficient web app that searched projects via the GitHub API through keywords.",
          "Documenting Node.js processes, including database schemas, as well as preparing reports",
          "Recommending and implementing improvements to processes and technologies",
          "Keeping informed of advancements in the field of Node.js development..",
        ]
    },
    {
      role: "Full Stack Developer",
      company: "Freelancer",
      companylogo: require("./assets/images/freelancer.jpg"),
      date: "1 Jun 2023 – Present",
      desc:
        "Web  App Developer , with  React Js and  Gatsby  Js.",
        descBullets: [

          "Responsible to Style ,look and feel of the web page with SASS that extends CSS with dynamic behavior such as variable , mixing, operations and functions.",
          "Implemented various screens for the front end using React.js and used various predefined components from NPM (Node Package Manager) and redux library.",
          "Worked on React JS Virtual Dom and React views, rendering using components which contains additional components called custom HTML tags.",
          "Worked in using React JS components, Forms, Events, Keys, Router, Animations, and Flux concept.",
          "Used React JS for templating for faster compilation and developing reusable components.",
          "Responsible for React UI and architecture.Building components ibrary,including Tree,Slide-View,and Table Grid.",
          "Implemented flux pattern by using redux framework as a core dependency.",
          "Utilized React for its efficient data flow architecture to create a lightweight and render efficient web app that searched projects via the GitHub API through keywords.",
          "Documenting Node.js processes, including database schemas, as well as preparing reports",
          "Recommending and implementing improvements to processes and technologies",
          "Keeping informed of advancements in the field of Node.js development..",
        ]
    }
    
    
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  githubConvertedToken: process.env.REACT_APP_GITHUB_TOKEN,
  githubUserName: "hamdihalimi", // Change to your github username to view your profile in Contact Section.
  showGithubProfile: "true", // Set true or false to show Contact profile using Github, defaults to true
  display: false // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigProjects = {
  
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: emoji("Achievements And Certifications 🏆 "),
  subtitle:
    "Achievements, Certifications, Award Letters and Some Cool Stuff that I have done !",

  achievementsCards: [
    {
      title: "Google Code-In Finalist",
      subtitle:
        "First Pakistani to be selected as Google Code-in Finalist from 4000 students from 77 different countries.",
      image: require("./assets/images/codeInLogo.webp"),
      footerLink: [
        {
          name: "Certification",
          url:
            ""
        },
        {
          name: "Award Letter",
          url:
            ""
        },
        {
          name: "Google Code-in Blog",
          url:
            ""
        }
      ]
    },
    {
      title: "Google Assistant Action",
      subtitle:
        "Developed a Google Assistant Action JavaScript Guru that is available on 2 Billion devices world wide.",
      image: require("./assets/images/googleAssistantLogo.webp"),
      footerLink: [
        {
          name: "View Google Assistant Action",
          url:
            ""
        }
      ]
    },

    {
      title: "PWA Web App Developer",
      subtitle: "Completed Certifcation from SMIT for PWA Web App Development",
      image: require("./assets/images/pwaLogo.webp"),
      footerLink: [
        {name: "Certification", url: ""},
        {
          name: "Final Project",
          url: ""
        }
      ]
    }
  ],
  display: false // Set false to hide this section, defaults to true
};

// Blogs Section

const blogSection = {
  title: "Blogs",
  subtitle:
    "With Love for Developing cool stuff.",

  blogs: [
    {
      url:
        "https://karrota.wtf/",
      title: "Karrota creative agency . ",
      description:
        "Leading creative agency in Kosova and Albania. Cannes Gold Lion Award winners. Creators of NEWBORN Monument."
    },
    {
      url: "https://cms-direktwerbung.de/",
      title: "CMS-DIREKTWERBUNG ",
      description:
        "We are your distributor in the Rhine-Main area and we are also responsible for your direct marketing throughout Germany, inexpensive, flexible and on time with our own control system. Test us and request a non-binding offer. Our quality management and comprehensive reporting give you a high level of security for a successful direct delivery.."
    },
    {
      url: "https://sca-dienstleistung.de/",
      title: "SCA DIENSTLEISTUNG ",
      description:
        "Wir übernehmen für Sie als Privat- oder Geschäftskunde die Demontage und fachgerechte Entsorgung von Asbestzementprodukten und schwach gebundenen Asbestprodukten nach Gefahrenstoffverordnung TRGS 519.."
    },
    {
      url: "http://monet.tv/",
      title: "Monet",
      description:
        "MONET IS A COMPREHENSIVE POST PRODUCTION FACILITY BASED IN THE YOUNGEST COUNTRY IN THE WORLD KOSOVA, FOR FILM, DOCUMENTARIES, COMMERCIALS AND MUSIC VIDEOS. KNOWN FOR ITS CREATIVE TEAM, PIONEERING TECHNOLOGY AND GLOBAL REACH, MONET IS TRUSTED TO DELIVER A QUALITY PRODUCT EVERY TIME. IT IS HOME TO ONE OF THE LARGEST AND MOST TALENTED POOLS OF EDITORS, COLORISTS AND VISUAL EFFECTS ARTISTS WORKING IN POST PRODUCTION TODAY WITH A CLIENT LIST THAT INCLUDES MANY OF THE MOST RESPECTED AND TALENTED FILM, MUSIC AND ENTERTAINMENT PROFESSIONALS IN THE INDUSTRY..."
    },
    {
      url: "https://www.triokos.com/",
      title: "TrioKOS",
      description:
        "TrioKos is a leading software development company based in Prishtina (Kosovo). We are a team of dedicated and passionate individuals who share a common goal: to create innovative software solutions that make a difference in people's lives,Since 2016, we specialize in providing innovative and customized software solutions to clients across the globe. Our areas of expertise include custom software development, website and mobile development, e-commerce, UX/UI design, dedicated teams, and tailored software solutions."
    },
    {
      url: "https://triocall.triokos.com/",
      title: "Trio Call",
      description:
        "Trio Call is a chat is an online face-to-face, visual communication performed with other Internet users by using a webcam and dedicated software. ."
    },
    {
      url: "https://game.peacockwarriors.com/",
      title: "Peacockwarriors Game",
      description:
        "Peacockwarriors Game is a game  2D , designed for challenges between customers owning a Peacockwarriors NFT, whoever has the most wins in a month wins the specified prize."
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Talks Sections

const talkSection = {
  title: "TALKS",
  subtitle: (
    ""
  ),

  talks: [
    {
      title: "",
      subtitle: "",
      slides_url: "",
      event_url: ""
    }
  ],
  display: false // Set false to hide this section, defaults to true
};

// Podcast Section

const podcastSection = {
  title: emoji("Podcast 🎙️"),
  subtitle: "I LOVE TO TALK ABOUT MYSELF AND TECHNOLOGY",

  // Please Provide with Your Podcast embeded Link
  podcast: [
    ""
  ],
  display: false // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: ("Contact Me"),
  subtitle:
    "Discuss a project or just want to say hi? My Inbox is open for all.",
  number: "(+383)49 226 870",
  email_address: "hamdihalimi93@gmail.com"
};

// Twitter Section

const twitterDetails = {
  userName: "hamdihalimi", //Replace "twitter" with your twitter username without @
  display: false // Set true to display this section, defaults to false
};

export {
  illustration,
  greeting,
  socialMediaLinks,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  blogSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails
};
